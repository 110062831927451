import React, { useEffect, useState, useRef } from "react"
import { Tabs } from "antd"

// import useInterval from "../utils/useInterval"
import Layout from "../layouts"

import "./component.css"

const { TabPane } = Tabs

const panes = new Array(4).fill("pane")

export default () => {
  const [defaultPane, setDefaultPane] = useState(0)
  const timeOutId = useRef(0)

  function setActiveTab(val) {
    let num = parseInt(val)
    setDefaultPane(num)
  }

  useEffect(() => {
    clearTimeout(timeOutId.current)
    timeOutId.current = setTimeout(() => {
      setActiveTab((defaultPane + 1) % panes.length)
    }, 1000)

    return () => {
      clearTimeout(timeOutId.current)
    }
  }, [defaultPane])

  return (
    <Layout title="简历排版">
      <div className="demonstration">
        <div className="video">
          <span style={{ color: "white" }}>video</span>
        </div>
      </div>
      <div className="carousel">
        <Tabs
          activeKey={`${defaultPane}`}
          onTabClick={val => setActiveTab(val)}
        >
          {panes.map((item, index) => (
            <TabPane tab={item} key={index}>
              <div className="tabpane-content">
                <span>{`${item}${index}`}</span>
              </div>
            </TabPane>
          ))}
        </Tabs>
      </div>
      <div className="theme-image">
        <img
          src="https://files.mdnice.com/blue.jpg"
          alt="mock"
        />
      </div>
    </Layout>
  )
}
